<template>
  <div>
    <base-modal :showing="visible" @close="$emit('close')" @opened="onOpened">
      <div class="flex items-center justify-between">
        <div class="flex items-center space-x-4">
          <div>
            <h3 class="text-lg font-bold leading-6 text-gray-900">
              Detail Cashbill
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Detail Cashbill Nuera
            </p>
          </div>
        </div>
      </div>
      <div class="border-t border-gray-200 pt-6 mt-6">
        <dl class="grid grid-cols-3 gap-x-4 gap-y-6">
          <div class="w-full">
            <dt class="text-xs text-gray-700">Nomor Cashbill</dt>
            <dd class="mt-1 text-sm font-bold text-gray-900">
              {{ cashbill.data.attributes.code }}
            </dd>
          </div>
          <div class="w-full">
            <dt class="text-xs text-gray-700">Dibuat Tanggal</dt>
            <dd class="mt-1 text-sm font-bold text-gray-900">
              {{ cashbill.data.attributes.date | formatDate('ll') }}
            </dd>
          </div>
          <div class="w-full">
            <dt class="text-xs text-gray-700">Periode</dt>
            <dd class="mt-1 text-sm font-bold text-gray-900">
              {{ cashbill.data.attributes.period }}
            </dd>
          </div>
          <div class="w-full">
            <dt class="text-xs text-gray-700">Kode Stockist</dt>
            <dd class="mt-1 text-sm font-bold text-gray-900">
              {{ cashbill.data.attributes.origin_office_code }}
            </dd>
          </div>
          <div class="w-full">
            <dt class="text-xs text-gray-700">Kode Mitra Usaha</dt>
            <dd class="mt-1 text-sm font-bold text-gray-900">
              {{ cashbill.data.attributes.destination_office_code }}
            </dd>
          </div>
          <div class="w-full">
            <dt class="text-xs text-gray-700">Total PV</dt>
            <dd class="mt-1 text-sm font-bold text-gray-900">
              {{ cashbill.data.attributes.total_pv | toCurrency }}
            </dd>
          </div>
          <div class="w-full">
            <dt class="text-xs text-gray-700">Total BV</dt>
            <dd class="mt-1 text-sm font-bold text-gray-900">
              {{ cashbill.data.attributes.total_bv | toCurrency }}
            </dd>
          </div>
          <div class="w-full">
            <dt class="text-xs text-gray-700">Total Harga Member</dt>
            <dd class="mt-1 text-sm font-bold text-gray-900">
              {{ cashbill.data.attributes.total_member_price | toCurrency }}
            </dd>
          </div>
          <div class="w-full">
            <dt class="text-xs text-gray-700">Total Harga Konsumen</dt>
            <dd class="mt-1 text-sm font-bold text-gray-900">
              {{ cashbill.data.attributes.total_consumer_price | toCurrency }}
            </dd>
          </div>
          <div class="col-span-3">
            <datatable :paginated="false" :columns="detailColumns">
              <template v-slot:tbody="{ classes }">
                <tr
                  v-for="(cashbillDetail) in getIncludedByType(cashbill, 'cashbill-details')"
                  :key="cashbillDetail.id"
                  :class="[
                    classes.tr,
                  ]"
                >
                  <td :class="[classes.td]">
                    <span class="font-bold text-gray-900">{{
                      cashbillDetail.attributes.product_code
                    }}</span>
                  </td>
                  <td :class="[classes.td, 'text-right']">
                    {{ cashbillDetail.attributes.product_qty | toCurrency }}
                  </td>
                  <td :class="[classes.td, 'text-right']">
                    {{ cashbillDetail.attributes.total_pv }} /
                    {{ cashbillDetail.attributes.total_bv }}
                  </td>
                  <td :class="[classes.td, 'text-right']">
                    {{ cashbillDetail.attributes.total_member_price | toCurrency }} /
                    {{ cashbillDetail.attributes.total_consumer_price | toCurrency }}
                  </td>
                </tr>
              </template>
            </datatable>
          </div>
        </dl>
      </div>
    </base-modal>
    <loading v-if="loading" />
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue';
import { requestMixin } from '@/mixins/request/request';

export default {
  mixins: [requestMixin],
  props: {
    visible: Boolean,
    cashbillId: String
  },
  emits: ['close'],
  components: {
    BaseModal,
  },
  data() {
    return {
      loading: false,
      cashbill: {
        data: {
          attributes: {}
        }
      }
    }
  },
  computed: {
    detailColumns() {
      return [
        { id: 'product', name: 'Barang' },
        { id: 'qty', name: 'Jumlah', theadClass: 'text-right' },
        { id: 'pv_bv', name: 'PV/BV', theadClass: 'text-right' },
        { id: 'price', name: 'Harga Member / Harga Konsumen', theadClass: 'text-right' }
      ]
    }
  },
  methods: {
    onOpened() {
      this.loadCashbill()
    },
    async loadCashbill() {
      this.loading = true;

      const [res, error] = await this.request(`/api/v1/cashbills/${this.cashbillId}`, {
        params: {
          include: 'cashbill-details'
        }
      });

      if (!error) {
        this.cashbill = res;
      }

      this.loading = false;
    },
  }
};
</script>
