<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <BaseCard padless>
      <nav
        class="flex rounded-t-lg border-b border-gray-200 bg-white"
      >
        <button
          v-for="(tab, index) in tabs"
          :key="index"
          type="button"
          @click="changeTab(tab)"
          class="max-w-xs flex-1 overflow-hidden border-r border-gray-200 py-4 px-4 text-center text-sm font-medium hover:bg-gray-50"
          :class="
            tab === tabActive ? 'bg-gray-50 text-green-600' : 'text-gray-500'
          "
        >
          <span>{{ tab }}</span>
        </button>
      </nav>

      <div class="px-4 py-5 sm:p-6">
        <keep-alive>
          <cashbill-nuera-table v-if="tabActive === 'Cashbill Nuera'" :columns="['code', 'date', 'period', 'total_pv', 'total_bv', 'total_member_price', 'total_consumer_price']" />
        </keep-alive>
        <div
          v-if="tabActive === 'Bulan Berjalan' || tabActive === 'Bulan Lalu'"
          class="flex flex-col space-y-4"
        >
          <div class="flex items-center justify-end space-x-2">
            <base-input
              @change="onChangeDate"
              v-model="startDate"
              label="Dari"
              type="date"
              name="filter"
              id="from"
            />
            <base-input
              @change="onChangeDate"
              v-model="endDate"
              label="Sampai"
              type="date"
              name="filter"
              id="to"
            />
            <base-input label="Status">
              <select
                v-model="is_completed"
                @change="filterByCompleted"
                class="focus:outline-none block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green-500 focus:ring-green-500 sm:text-sm"
              >
                <option :value="null">Semua</option>
                <option :value="true">Valid</option>
                <option :value="false">Draft</option>
              </select>
            </base-input>
            <base-search
              v-model="search"
              label="Search"
              @input="debounceSearch"
              placeholder="Cari kode atau nama"
            />
          </div>
          <div class="flex sm:-mx-6 lg:-mx-8">
            <div
              class="inline-block min-w-full align-middle sm:px-6 lg:px-8"
            >
              <Datatable
                :total="getOrders.meta.page.total"
                :perPage="getOrders.meta.page.perPage"
                :currentPage="getOrders.meta.page.currentPage"
                @pagechanged="onPageChangeOrder"
              >
                <template v-slot:thead>
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Nomor / Stockist
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Total Harga
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        PV
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        BV
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Status Faktur
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:tbody>
                  <tbody v-if="getOrders.data.length">
                    <tr
                      class="cursor-pointer bg-white hover:bg-green-100"
                      v-for="(data, dataIdx) in getOrders.data"
                      :key="data.id"
                      :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                      @click.prevent="tableDetails(data)"
                    >
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        <div class="text-sm font-bold text-gray-900">
                          {{ data.attributes.destination_code }} /
                          <span class="text-xs text-gray-400">{{
                            data.attributes.origin_code
                          }}</span>
                        </div>
                        <div class="text-xs font-light text-gray-300">
                          {{ dayjs(data.attributes.updatedAt).format('ll LT') }}
                        </div>
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-24 text-left text-sm text-gray-500"
                      >
                        {{
                          getRelationships(
                            getOrders,
                            data.relationships['order'].data.id
                          ).attributes.final_price | toCurrency
                        }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-24 text-left text-sm text-gray-500"
                      >
                        {{
                          getRelationships(
                            getOrders,
                            data.relationships['order'].data.id
                          ).attributes.grand_total_pv | toCurrency
                        }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-24 text-left text-sm text-gray-500"
                      >
                        {{
                          getRelationships(
                            getOrders,
                            data.relationships['order'].data.id
                          ).attributes.total_bonus_value | toCurrency
                        }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-left text-sm text-gray-500"
                      >
                        <base-badge>
                          {{ data.attributes.order_status }}
                        </base-badge>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-if="getOrders.data.length">
                    <tr class="bg-gray-50">
                      <td
                        class="whitespace-nowrap px-6 py-4 text-right text-sm font-medium text-gray-500"
                      ></td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        {{ summary.grandTotalPrice | toCurrency }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        {{ summary.totalPointValue | toCurrency }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                        colspan="3"
                      >
                        {{ summary.totalBonusValue | toCurrency }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr class="bg-white">
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                        colspan="8"
                      >
                        Data tidak ditemukan
                      </td>
                    </tr>
                  </tbody>
                </template>
              </Datatable>
            </div>
          </div>

          <!-- Modal -->
          <transition
            name="customFade"
            enter-active-class="fadeIn"
            leave-active-class="fadeOut"
          >
            <div
              v-if="openDetail"
              style="animation-duration: 0.3s"
              class="fixed inset-0 z-10 mx-4 overflow-x-auto"
              aria-labelledby="modal-title"
              role="dialog"
              aria-modal="true"
            >
              <div
                class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
              >
                <div
                  class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                  aria-hidden="true"
                ></div>
                <span
                  class="hidden sm:inline-block sm:h-screen sm:align-middle"
                  aria-hidden="true"
                  >&#8203;</span
                >
                <div
                  class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
                >
                  <div class="mb-8 flex justify-between">
                    <div class="flex items-center space-x-4">
                      <div>
                        <h3 class="text-lg font-bold leading-6 text-gray-900">
                          Detail Cashbill
                        </h3>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">
                          Data Cashbill masuk
                        </p>
                      </div>
                    </div>
                    <div>
                      <img
                        src="@/assets/images/logo.png"
                        alt=""
                        class="flex justify-end"
                      />
                    </div>
                  </div>
                  <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl class="flex flex-row gap-x-4">
                      <div class="w-full">
                        <dt class="text-sm font-bold text-gray-700">
                          No Cashbill
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{ dataPembelian.attributes.destination_code }}
                        </dd>
                      </div>
                      <div class="w-full">
                        <dt class="text-sm font-bold text-gray-700">Tanggal</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{
                            dayjs(dataPembelian.attributes.updatedAt).format(
                              'll LT'
                            )
                          }}
                        </dd>
                      </div>
                    </dl>
                    <dl class="mt-6 flex flex-row gap-x-4">
                      <div class="w-full">
                        <dt class="text-sm font-bold text-gray-700">
                          Nomor Stockist
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{
                            dataPembelian.relationships['origin-office'].data
                              ? getRelationships(
                                  getOrders,
                                  dataPembelian.relationships['origin-office']
                                    .data.id
                                ).attributes.code
                              : '-'
                          }}
                        </dd>
                      </div>
                      <div class="w-full">
                        <dt class="text-sm font-bold text-gray-700">
                          Nama Stockist
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{
                            dataPembelian.relationships['origin-office'].data
                              ? getRelationships(
                                  getOrders,
                                  dataPembelian.relationships['origin-office']
                                    .data.id
                                ).attributes.name
                              : '-'
                          }}
                        </dd>
                      </div>
                    </dl>
                    <dl class="mt-6 flex flex-row gap-x-4">
                      <div class="w-full">
                        <dt class="text-sm font-bold text-gray-700">
                          Nomor Upline
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{ getUpline.data[0]?.attributes.code ?? '-' }}
                        </dd>
                      </div>
                      <div class="w-full">
                        <dt class="text-sm font-bold text-gray-700">
                          Nama Upline
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{ getUpline.data[0]?.attributes.name ?? '-' }}
                        </dd>
                      </div>
                    </dl>
                    <div class="my-6 sm:col-span-6">
                      <Datatable
                        :paginated="false"
                        :footer="false"
                        class="mt-4"
                      >
                        <template v-slot:thead>
                          <thead class="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                KODE BARANG
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                JUMLAH
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                PV
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Total PV
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                BV
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Total BV
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Harga Distributor
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                TOTAL HARGA
                              </th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:tbody>
                          <tbody>
                            <tr
                              class="bg-white"
                              v-for="(barang, index) in dataPembelian
                                .relationships['order-details'].data"
                              :key="index"
                            >
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                              >
                                <div class="text-sm font-bold text-gray-900">
                                  {{
                                    getRelationships(getOrders, barang.id)
                                      .attributes.product_code
                                  }}
                                </div>
                                <div class="text-xs font-light text-gray-300">
                                  {{
                                    getRelationships(getOrders, barang.id)
                                      .attributes.product_name
                                  }}
                                </div>
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                              >
                                {{
                                  getRelationships(getOrders, barang.id)
                                    .attributes.product_qty | toCurrency
                                }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                              >
                                {{
                                  getRelationships(getOrders, barang.id)
                                    .attributes.point_value | toCurrency
                                }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                              >
                                {{
                                  (getRelationships(getOrders, barang.id)
                                    .attributes.point_value *
                                    getRelationships(getOrders, barang.id)
                                      .attributes.product_qty)
                                    | toCurrency
                                }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                              >
                                {{
                                  getRelationships(getOrders, barang.id)
                                    .attributes.bonus_value | toCurrency
                                }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                              >
                                {{
                                  (getRelationships(getOrders, barang.id)
                                    .attributes.bonus_value *
                                    getRelationships(getOrders, barang.id)
                                      .attributes.product_qty)
                                    | toCurrency
                                }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                              >
                                {{
                                  getRelationships(getOrders, barang.id)
                                    .attributes.product_price | toCurrency
                                }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                              >
                                {{
                                  getRelationships(getOrders, barang.id)
                                    .attributes.total_price | toCurrency
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </Datatable>
                    </div>
                    <div class="flex">
                      <div class="flex w-2/3 flex-col gap-y-2">
                        <span
                          v-if="dataPembelian.attributes.is_completed"
                          class="inline-flex w-24 items-center justify-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                        >
                          Selesai
                        </span>
                        <span
                          v-else
                          class="inline-flex w-36 items-center justify-center rounded-full bg-red-100 px-3 py-1 text-center text-sm font-medium text-red-800"
                        >
                          Belum Selesai
                        </span>
                      </div>
                      <div class="flex w-1/3 flex-col gap-y-2">
                        <div class="inline-flex w-full justify-between">
                          <div class="sm:col-span-1">
                            <label
                              for="invoice"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Total PV
                            </label>
                          </div>
                          <div class="sm:col-span-1">
                            <label
                              for="invoice"
                              class="block text-sm font-bold text-gray-900"
                            >
                              {{ grand_pv }}
                            </label>
                          </div>
                        </div>
                        <div class="inline-flex w-full justify-between">
                          <div class="sm:col-span-1">
                            <label
                              for="invoice"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Total BV
                            </label>
                          </div>
                          <div class="sm:col-span-1">
                            <label
                              for="invoice"
                              class="block text-sm font-bold text-gray-900"
                            >
                              {{ grand_bv }}
                            </label>
                          </div>
                        </div>
                        <div class="inline-flex w-full justify-between">
                          <div class="sm:col-span-1">
                            <label
                              for="invoice"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Total harga keseluruhan
                            </label>
                          </div>
                          <div class="sm:col-span-1">
                            <label
                              for="invoice"
                              class="block text-sm font-bold text-gray-900"
                            >
                              {{
                                dataPembelian.attributes.final_price
                                  | toCurrency
                              }}
                            </label>
                          </div>
                        </div>
                        <div class="inline-flex w-full justify-between">
                          <div class="sm:col-span-1">
                            <label
                              for="invoice"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Total berat keseluruhan
                            </label>
                          </div>
                          <div class="sm:col-span-1">
                            <label
                              for="invoice"
                              class="block text-sm font-bold text-gray-900"
                            >
                              {{ dataPembelian.attributes.grand_total_weight }}
                              Kg
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-end">
                    <button
                      v-if="!dataPembelian.attributes.is_valid_for_payment"
                      @click="validasi(dataPembelian)"
                      class="focus:outline-none mr-2 inline-flex items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="mr-2 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                        />
                      </svg>
                      Validasi
                    </button>
                    <button
                      @click="openDetail = !openDetail"
                      type="button"
                      class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                    >
                      Tutup
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import { StorageService } from '@/services/storage.service';
import { beginningOfMonth, endOfMonth } from '@/services/utils.service';
import BaseSearch from '@/components/base/Search.vue';
import CashbillNueraTable from '@/components/cashbill-nuera/cashbill-nuera-table.vue'

import dayjs from 'dayjs';
export default {
  name: 'Home',
  components: {
    Datatable,
    BaseSearch,
    CashbillNueraTable
  },
  data() {
    const begin = beginningOfMonth(new Date());
    const end = endOfMonth(new Date());

    const startDate = dayjs(begin).format('YYYY-MM-DD');
    const endDate = dayjs(end).format('YYYY-MM-DD');

    const destination_warehouse_id = StorageService.getUser().current_warehouse;

    const today = dayjs(); // Get the current date
    const firstDayOfPrevMonth = today.subtract(1, 'month').startOf('month');
    const lastDayOfPrevMonth = today.subtract(1, 'month').endOf('month');

    return {
      destination_warehouse_id,
      search: null,
      is_completed: true, // null berarti "semua" terfetch
      openDetail: false,
      dataPembelian: {},
      startDate,
      endDate,
      grand_pv: 0,
      grand_bv: 0,
      tabs: ['Bulan Berjalan', 'Bulan Lalu', 'Cashbill Nuera'],
      tabActive: 'Bulan Berjalan',
      firstDayOfPrevMonth: firstDayOfPrevMonth.format('YYYY-MM-DD'),
      lastDayOfPrevMonth: lastDayOfPrevMonth.format('YYYY-MM-DD'),
    };
  },
  created() {
    this.fetchOrders({
      ...this.ordersParams,
    });
    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
    this.fetchUpline({
      id: StorageService.getUser().office_id,
    });
  },
  computed: {
    ...mapGetters({
      getUpline: 'members/getUpline',
      getOrders: 'orders/getOrders',
    }),
    ordersParams() {
      return {
        'filter[destination_warehouse_id]': this.destination_warehouse_id,
        'page[limit]': 5,
        'filter[destination_office_category_id]': 5,
        'filter[from_date]': this.startDate,
        'filter[to_date]': this.endDate,
        include: 'origin-office,order-details,order',
        'fields[orders]':
          'order-details,origin-office,item_count,origin_code,origin_office_code,destination_code,updatedAt,createdAt,grand_total_pv,total_bonus_value,final_price,origin_office_id,destination_warehouse_id,is_completed,is_valid_for_payment',
        'fields[order-details]':
          'product_code,product_name,product_qty,point_value,bonus_value,product_price,total_price',
      };
    },
    summary() {
      const orders = this.getOrders.included?.filter(
        (it) => it.type == 'orders'
      );
      const orderDetailsLength = orders.reduce(
        (a, b) => a + b.attributes.item_count,
        0
      );
      const grandTotalPrice = orders.reduce(
        (a, b) => a + b.attributes.final_price,
        0
      );
      const totalPointValue = orders.reduce(
        (a, b) => a + b.attributes.grand_total_pv,
        0
      );
      const totalBonusValue = orders.reduce(
        (a, b) => a + b.attributes.total_bonus_value,
        0
      );

      return {
        orderDetailsLength,
        grandTotalPrice,
        totalPointValue,
        totalBonusValue,
      };
    },
  },
  methods: {
    ...mapActions({
      fetchUpline: 'members/fetchUpline',
      fetchOrders: 'orders/fetchOrders',
      updateOrder: 'orders/updateOrder',
    }),
    dayjs,
    changeTab(tab) {
      this.tabActive = tab;

      if (tab !== 'Cashbill Nuera') {
        const today = dayjs(); // Get the current date
        const firstDayOfThisMonth = today.startOf('month').format('YYYY-MM-DD');
        const lastDayOfThisMonth = today.endOf('month').format('YYYY-MM-DD');
        if (tab == 'Bulan Lalu') {
          this.startDate = this.firstDayOfPrevMonth;
          this.endDate = this.lastDayOfPrevMonth;
        } else {
          this.startDate = firstDayOfThisMonth;
          this.endDate = lastDayOfThisMonth;
        }
        this.fetchOrders({
          ...this.ordersParams,
        });
      }
    },
    onChangeDate(e) {
      if (e.target.id === 'from') {
        this.fetchOrders({
          ...this.ordersParams,
          // "2022-09-01"
          fromDate: dayjs(e.target.value, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        });
      } else if (e.target.id === 'to') {
        this.fetchOrders({
          ...this.ordersParams,
          toDate: dayjs(e.target.value, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        });
      }
    },

    debounceSearch: debounce(function () {
      this.fetchOrders({
        ...this.ordersParams,
      });
    }, 300),
    onPageChangeOrder(page) {
      this.fetchOrders({
        ...this.ordersParams,
        pageNumber: page,
      });
    },
    filterByCompleted() {
      this.fetchOrders({
        ...this.ordersParams,
      });
    },
    validasi(data) {
      const payload = {
        data: {
          type: 'orders',
          id: data.id,
          attributes: {
            is_valid_for_payment: true,
          },
        },
      };
      this.updateOrder(payload).then((response) => {
        if (response) {
          this.openDetail = false;
          this.fetchOrders({
            destination_warehouse_id: this.destination_warehouse_id,
            pageNumber: 1,
            pageSize: 5,
            is_completed: this.is_completed,
          });
        }
      });
    },
    tableDetails(data) {
      let grand_pv = 0;
      let grand_bv = 0;

      this.openDetail = !this.openDetail;
      this.dataPembelian = data;

      // compute grand total pv and bv
      for (const order of this.dataPembelian.relationships['order-details']
        .data) {
        let id = order.id;
        let point_value = this.getRelationships(this.getOrders, id).attributes
          .point_value;
        let bonus_value = this.getRelationships(this.getOrders, id).attributes
          .bonus_value;
        let product_qty = this.getRelationships(this.getOrders, id).attributes
          .product_qty;
        grand_pv += parseFloat(point_value) * parseFloat(product_qty);
        grand_bv += parseFloat(bonus_value) * parseFloat(product_qty);
      }
      this.grand_bv = grand_bv;
      this.grand_pv = grand_pv;
    },
    sum(data, key) {
      return data.reduce((a, b) => a + parseFloat(b[key] || 0), 0);
    },
    getRelationships(resource, id) {
      return resource.included.find((el) => el.id === id);
    },
  },
};
</script>
