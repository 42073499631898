<template>
    <base-wrapper>
    <base-card title="Statement Komisi">
        <datatable
        :columns="officeCommissionsColumns"
        :scroll-x="false"
        :total="officeCommissions.meta.page.total"
        :perPage="officeCommissions.meta.page.perPage"
        :currentPage="officeCommissions.meta.page.currentPage"
        :meta="officeCommissions.meta"
        @pagechanged="onPageChanged"
        >
        <template v-slot:tbody="{ classes }">
            <tr
            v-for="officeCommission in officeCommissions.data"
            :key="officeCommission.id"
            :class="[classes.tr, 'bg-white hover:bg-green-100']"
            >
            <td :class="[classes.td]">
                <p class="font-bold text-gray-900">
                  {{ officeCommission.attributes.period }}
                </p>
            </td>
            <td :class="[classes.td]">
                {{ officeCommission.attributes.stage }}
            </td>
            <td :class="[classes.td, 'text-right']">
                {{ officeCommission.attributes.bonus | toCurrency }}
            </td>
            <td :class="[classes.td]">
                {{ officeCommission.attributes.bonus_pickup_office }}
            </td>
            </tr>
        </template>
        </datatable>
    </base-card>
      <loading v-if="loadingPage" />
    </base-wrapper>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import { requestMixin } from '@/mixins/request/request';
  
  export default {
    mixins: [requestMixin],
    data() {
      return {
        loadingOfficeCommissions: false,
        officeCommissions: {
          data: [],
          meta: {
            page: {},
          },
        },
      };
    },
    computed: {
      ...mapGetters({
        me: 'auth/getUser',
      }),
      officeCommissionsColumns() {
        return [
          { id: 'period', name: 'Periode' },
          { id: 'stage', name: 'Jenjang' },
          { id: 'bonus', name: 'Total Bonus', theadClass: 'text-right' },
          { id: 'bonus_pickup', name: 'Tempat Bonus' }
        ]
      },
      loadingPage() {
        return this.loadingOfficeCommissions
      }
    },
    methods: {
      onPageChanged(page) {
        this.loadOfficeCommissions({
          'page[number]': page
        })
      },
      async loadData() {
        this.loadOfficeCommissions()
      },
      async loadOfficeCommissions(params) {
        this.loadingOfficeCommissions = true;
  
        const [res, error] = await this.request(`/api/v1/offices/${this.me.office_id}/office-commissions`, {
          params: {
            'page[size]': 5,
            ...params,
          },
        });
  
        if (!error) {
          this.officeCommissions = res;
        }
  
        this.loadingOfficeCommissions = false;
      }
    },
    async created() {
      this.loadData()
    }
  };
  </script>
  